// Styles SCSS
import '../sass/app.scss';

// jQuery
import $ from 'jquery';

window.$ = window.jQuery = $;

// Masonry layout
import Masonry from './utils/masonry';

// SVG polyfiil
import 'svgxuse';

// Flash Info
import 'jquery.marquee';

// Accordions
import Accordion from 'accordion-js';

//Tabs
import {Tabs} from './utils/tabs';

// pushbar
import Pushbar from './utils/pushbar';

// Lightbox
import './utils/fancybox';

// LazyLoad
import lazyLoadInit from './lazyload-init';

import {TimelineLite, Power2} from 'gsap';

import InfiniteScroll from 'infinite-scroll';

import matchHeight from 'jquery-match-height';

import 'slick-carousel';

lazyLoadInit();

$(document).ready(() => {
  console.log("app_js");

  // Gtranslate
  jQuery('.translate option:contains("Select Language")').text('langues');
  jQuery('.translate option:contains("English")').text('EN');
  jQuery('.translate option:contains("French")').text('FR');
  jQuery('.translate option:contains("Spanish")').text('ES');
  jQuery('.translate option:contains("FR")').attr('selected', true);

  $('.ticker').marquee({
    duration: 13000,
    delayBeforeStart: 0,
    direction: 'left',
    duplicated: false,
    pauseOnHover: true
  });

  if (document.querySelector('.accordion-container') !== null) {
    new Accordion('.accordion-container');
    $('.accordion-container .ac').on('click', function () {
      var titre = $(this)
        .children('h3')
        .children('svg');
      if (titre.hasClass('fa-chevron-right')) {
        titre.removeClass('fa-chevron-right').addClass('fa-chevron-down');
      } else {
        titre.removeClass('fa-chevron-down').addClass('fa-chevron-right');
      }
    });
  }

  if (document.querySelector('.tabs-container') !== null) {
    new Tabs({
      elem: 'tabs',
      open: 0
    });
  }
  

  new Pushbar({
    blur: true,
    overlay: true
  });

  $('[data-fancybox="galerie"]').fancybox({
    thumbs: {
      autoStart: true
    }
  });


  // Animation barre de recherche:
  let tl = new TimelineLite();
  $('#open-search').click(function (e) {
    e.preventDefault();
    tl.to('#searchform', 0.1, {
      visibility: 'visible',
      ease: Power2.easeOut
    })
      .to('#searchform', 1, {
        opacity: 1,
        x: 0,
        height: '100%',
        ease: Power2.easeOut
      })
      .to('.form-container', 0.5, {
        opacity: 1,
        ease: Power2.easeOut
      });
  });

  $('#close-searchbar').click(function (e) {
    e.preventDefault();
    tl.to('.form-container', 0.25, {
      opacity: 0,
      ease: Power2.easeOut
    })
      .to('#searchform', 0.5, {
        opacity: 0,
        x: '500px',
        height: 0,
        ease: Power2.easeOut
      })
      .to('#searchform', 0.1, {
        visibility: 'hidden'
      });
  });


  // mode nuit / inversé
  function nightMode() {
    if (localStorage.getItem('stylesheet')) {
      localStorage.clear();
      document.getElementById('night-css').setAttribute('href', '');
    } else {
      localStorage.clear();
      document.getElementById('grey-css').setAttribute('href', '');
      localStorage.setItem('stylesheet', '/wp-content/themes/claye/night.css');
      document
        .getElementById('night-css')
        .setAttribute('href', localStorage.getItem('stylesheet'));
    }
  }

  function greyMode() {
    if (localStorage.getItem('stylesheetGrey')) {
      localStorage.clear();
      document.getElementById('grey-css').setAttribute('href', '');
    } else {
      localStorage.clear();
      document.getElementById('night-css').setAttribute('href', '');
      localStorage.setItem(
        'stylesheetGrey',
        '/wp-content/themes/claye/grey.css'
      );
      document
        .getElementById('grey-css')
        .setAttribute('href', localStorage.getItem('stylesheetGrey'));
    }
  }

  document.getElementById('night-mode').addEventListener('click', nightMode);
  document.getElementById('grey-mode').addEventListener('click', greyMode);

  // Infinite scroll Offres d'emploi

  if (document.querySelector('.archive-emplois') !== null) {
    var elem = document.querySelector('.archive-emplois');
    var infScroll = new InfiniteScroll(elem, {
      // options
      path: '.pagination-block .pagination .next a',
      append: '.archive-emplois article',
      history: false,
      hideNav: '.pagination-block',
      status: '.page-load-status'
    });

    // Relancer Match height quand nouvelle page chargée
    infScroll.on('append', function (response, path, items) {
      $('.archive-emplois header').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
      });
    });
  }

  // Match Height
  $('.archive-emplois header').matchHeight({
    byRow: true,
    property: 'height',
    target: null,
    remove: false
  });


  // Slide des actu dans les pages
  $('.slide-actu').slick({
		slidesToShow: 3,
  	slidesToScroll: 3,
		dots: true,
		infinite: false,
		autoplay: false,
    arrows: true,
    prevArrow: '<div class="prevArrow"><i class="far fa-chevron-left"></i><span class="sr-only">Prev</span></div>',
		nextArrow: '<div class="nextArrow"><i class="far fa-chevron-right"></i><span class="sr-only">Next</span></div>',
		autoplaySpeed: 2000,
    responsive: [{
      breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }, {

      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }]
	});
});

// BACK TO TOP
if ($('#back-to-top').length) {
  var scrollTrigger = 100, // px
    backToTop = function () {
      var scrollTop = $(window).scrollTop();
      if (scrollTop > scrollTrigger) {
        $('#back-to-top').addClass('show');
      } else {
        $('#back-to-top').removeClass('show');
      }
    };
  backToTop();
  $(window).on('scroll', function () {
    backToTop();
  });
  $('#back-to-top').on('click', function (e) {
    e.preventDefault();
    $('html,body').animate(
      {
        scrollTop: 0
      },
      700
    );
  });
}
