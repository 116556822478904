import LazyLoad from 'vanilla-lazyload';

const lazyLoadOptions = {
  elements_selector: '.lazy'
};

const createLazyLoadInstance = () => {
  return new LazyLoad(lazyLoadOptions);
};

export default () => {
  document.addEventListener('DOMContentLoaded', createLazyLoadInstance);
};
